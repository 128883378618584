import axios from "axios";

export const workBitrix = async (method:string, data: any) => {
    const response = await axios({
        url: 'https://oper-portal.bitrix24.ru/rest/13139/kkw95sa5pt2nr4a6/' + method,
        method: 'post',
        data: data
    })

    return response.data
}