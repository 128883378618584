import {IFormValues} from "../types/IFormValues";
import createContactsClients from "./createContactsClients";
import {workBitrix} from "./workInBitrix";

const queryParams = new URLSearchParams(window.location.search);

export default async (values:IFormValues) => {
    const contacts_id = await createContactsClients(values)

    const id = queryParams.get('id')
    const contact_id = queryParams.get('contact_id')

    const id_user = await workBitrix('crm.deal.get', {id: id})

    const data = {
        id: contact_id,
        fields: {
            NAME: values.firstName,
            SECOND_NAME: values.secondName,
            LAST_NAME: values.lastName,
            TYPE_ID: "CLIENT",
            ADDRESS: values.addressRegistration,
            PHONE: [{"VALUE": values.phone, "VALUE_TYPE": "WORK"}],
            BIRTHDATE: new Date(values.dateBorn).toLocaleDateString(),
            UF_CRM_1656310233566: values.isMarried?.value,
            UF_CRM_1656310300932: values.phoneWife,
            EMAIL: [{"VALUE": values.email, "VALUE_TYPE": "WORK"}],
            UF_CRM_1656311428461: values.passportData,
            UF_CRM_1656311472121: new Date(values.issuePassport).toLocaleDateString(),
            UF_CRM_1656311498190: values.authorityPassport,
            UF_CRM_1656311849051: values.placeWork,
            UF_CRM_1656313241516: values.isProperty.map(property => property.value),
            UF_CRM_1656323347: values.address,
            UF_CRM_1670558520: contacts_id,
            CONTACT_ID: id_user.result.ASSIGNED_BY_ID
        }
    }

    const id_contact = await workBitrix('crm.contact.update', data)

    await workBitrix('crm.deal.update', {id: id, fields: {client: id_contact.result}})
}