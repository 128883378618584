import {IFormValues} from "../types/IFormValues";
import {workBitrix} from "./workInBitrix";

export default async (values:any) => {
    const contacts_id: number[] = []

    for (let i = 1; i <= 4; i++) {
        if( !(values[`firstName_client_${i}`] === '' &&  values[`secondName_client${i}`] && values[`lastName_client_${i}`] && values[`address_client_${i}`] && values[`phone_client_${i}`]) ) {
            const data = {
                fields: {
                    NAME: values[`firstName_client_${i}`],
                    SECOND_NAME: values[`secondName_client${i}`],
                    LAST_NAME: values[`lastName_client_${i}`],
                    TYPE_ID: "UC_UR419L",
                    ADDRESS: values[`address_client_${i}`],
                    PHONE: [{"VALUE": values[`phone_client_${i}`], "VALUE_TYPE": "WORK"}]
                }
            }

            const response = await workBitrix('crm.contact.add', data)

            contacts_id.push(response.result)
        }
    }

    return contacts_id
}