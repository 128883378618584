import React, {useEffect, useState} from 'react';
import Form from "./components/Form/Form";
import {IFormValues} from "./types/IFormValues";
import createContactClient from "./hooks/CreateContactClient";
import {Alert} from "@mui/material";
import {workBitrix} from "./hooks/workInBitrix";

const App = () => {

    const [modal, setModal] = useState<boolean>(false)
    const queryParams = new URLSearchParams(window.location.search);

    const getDataForm = async (values: IFormValues, reset:any) => {
        await createContactClient(values)

        setModal(true)
        reset()

        setTimeout(() => {
            setModal(false)
        }, 4000)
    }

    return (
        <div className={'container mx-auto px-2'}>
            {modal && <Alert style={{
                position: 'fixed',
                top: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 'auto',
                zIndex: 30

            }} severity="success">Сообщение успешно довставлено</Alert>}
            <Form workDataForm={getDataForm}/>
        </div>
    );
};

export default App;