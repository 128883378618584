import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Button, TextField} from "@mui/material";
import {IFormValues, IProperty} from "../../types/IFormValues";
import {DatePicker, DesktopDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import Select from "react-select";
import {MuiTelInput} from "mui-tel-input";
import validator from "validator";
import ruLocale from 'date-fns/locale/ru'

const Form = ({workDataForm}: { workDataForm: any }) => {

    const {
        handleSubmit,
        formState: {errors, isSubmitting},
        control,
        reset
    } = useForm<IFormValues>({
        mode: 'onChange',
        defaultValues: {
            firstName: "",
            lastName: "",
            secondName: "",
            isMarried: null,
            phone: "",
            phoneWife: "",
            email: "",
            passportData: "",
            authorityPassport: "",
            placeWork: "",
            isProperty: [],
            address: "",
            addressRegistration: "",
            dateBorn: new Date(),
            issuePassport: new Date(),
            incomeLevel: null,

            whoIs_client_1: "",
            whoIs_client_2: "",
            whoIs_client_3: "",
            whoIs_client_4: "",

            firstName_client_1: "",
            firstName_client_2: "",
            firstName_client_3: "",
            firstName_client_4: "",

            lastName_client_1: "",
            lastName_client_2: "",
            lastName_client_3: "",
            lastName_client_4: "",

            secondName_client_1: "",
            secondName_client_2: "",
            secondName_client_3: "",
            secondName_client_4: "",

            phone_client_1: "",
            phone_client_2: "",
            phone_client_3: "",
            phone_client_4: "",

            address_client_1: "",
            address_client_2: "",
            address_client_3: "",
            address_client_4: ""
        }
    })

    const isMarriedOptions: IProperty[] | unknown = [
        {value: '571', label: 'Холост/не замежем'},
        {value: '567', label: 'Женат/Замужем'},
        {value: '569', label: 'В разводе'},
        {value: '573', label: 'Вдова/Вдовец'},
    ]

    const isPropertyOptions: IProperty[] = [
        {value: '579', label: 'Съемное жилье'},
        {value: '575', label: 'Наличие жилья в собственности'},
        {value: '577', label: 'Наличие машины в собственности'}
    ]

    const onSubmit = (values: IFormValues) => {
        console.log('test')
        return workDataForm(values, reset)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`flex flex-wrap justify-between`}>

                <div className={`lg:w-[47%] w-full`}>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Имя'}
                                error={!!errors?.firstName}
                                helperText={errors?.firstName?.message}
                            />}
                    />

                    <Controller
                        name="lastName"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Фамилия'}
                                error={!!errors?.lastName}
                                helperText={errors?.lastName?.message}
                            />}
                    />

                    <Controller
                        name="secondName"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Отчество'}
                                error={!!errors?.secondName}
                                helperText={errors?.secondName?.message}
                            />}
                    />

                    <Controller
                        name="dateBorn"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    {...field}
                                    renderInput={
                                        (param) => <TextField error={!!errors?.dateBorn} fullWidth margin={'normal'}
                                                              helperText={errors?.dateBorn?.message} {...param} />
                                    }
                                    label={'Дата рождения'}
                                />
                            </LocalizationProvider>}
                    />

                    <Controller
                        name="isMarried"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <>
                                <Select
                                    {...field}
                                    //@ts-ignore
                                    options={isMarriedOptions}
                                    placeholder={'Семейное положение'}
                                    className={`${errors?.isMarried && 'border-red-500 border'} rounded z-20`}
                                />
                                {errors?.isMarried?.message &&
                                    <div className={'text-xs ml-4 text-red-500'}>{errors.isMarried.message}</div>}
                            </>
                        }
                    />

                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required: 'Заполните поле',
                            validate: (value) => {

                                const phone = value.replaceAll(' ', '')
                                
                                if (!validator.isMobilePhone(phone))
                                    return 'Введите коректный номер телфона'

                                return undefined
                            }
                        }}
                        render={({field}) =>
                            <MuiTelInput label={'Телефон'} langOfCountryName={'RU'} error={!!errors?.phone}
                                         helperText={errors?.phone?.message} {...field} fullWidth
                                          margin={'normal'}/>}
                    />

                    <Controller
                        name="phoneWife"
                        control={control}
                        render={({field}) =>
                            <MuiTelInput label={'номер телефона супруги/супруга'} langOfCountryName={'RU'}
                                         error={!!errors?.phoneWife} helperText={errors?.phoneWife?.message} {...field}
                                         fullWidth  margin={'normal'}/>}
                    />

                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            validate: (value) => {
                                if (validator.isEmpty(value))
                                    return undefined

                                if (!validator.isEmail(value))
                                    return 'Введите коректный E-mail'

                                return undefined
                            }
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'E-mail'}
                                error={!!errors?.email}
                                helperText={errors?.email?.message}
                            />}
                    />

                </div>

                <div className={`lg:w-[47%] w-full`}>

                    <Controller
                        name="passportData"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Номер и серия паспорта'}
                                error={!!errors?.passportData}
                                helperText={errors?.passportData?.message}
                            />}
                    />

                    <Controller
                        name="issuePassport"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    {...field}
                                    renderInput={
                                        (param) => <TextField error={!!errors?.issuePassport} fullWidth
                                                              margin={'normal'}
                                                              helperText={errors?.issuePassport?.message} {...param} />
                                    }
                                    label={'Дата выдачи паспорта'}
                                />
                            </LocalizationProvider>}
                    />

                    <Controller
                        name="authorityPassport"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Орган, выдавший паспорт'}
                                error={!!errors?.authorityPassport}
                                helperText={errors?.authorityPassport?.message}
                            />}
                    />

                    <Controller
                        name="placeWork"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Место работы клиента'}
                                error={!!errors?.placeWork}
                                helperText={errors?.placeWork?.message}
                            />}
                    />

                    <Controller
                        name="isProperty"
                        control={control}
                        render={({field}) =>
                            <>
                                <Select
                                    {...field}
                                    closeMenuOnSelect={false}
                                    //@ts-ignore
                                    options={isPropertyOptions}
                                    placeholder={'Наличие имущества'}
                                    className={`${errors?.isProperty && 'border-red-500 border'} rounded z-20`}
                                    isMulti
                                />
                                {errors?.isProperty?.message &&
                                    <div className={'text-xs ml-4 text-red-500'}>{errors.isProperty.message}</div>}
                            </>
                        }
                    />

                    <Controller
                        name="address"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Адрес'}
                                error={!!errors?.address}
                                helperText={errors?.address?.message}
                            />}
                    />

                    <Controller
                        name="addressRegistration"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Адрес по прописке'}
                                error={!!errors?.addressRegistration}
                                helperText={errors?.addressRegistration?.message}
                            />}
                    />

                    <Controller
                        name="incomeLevel"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth
                                label={'Уровень дохода'}
                                error={!!errors?.incomeLevel}
                                helperText={errors?.incomeLevel?.message}
                                type={'number'}
                            />}
                    />

                </div>

                <div className={`lg:w-[47%] w-full mt-4`}>
                    <Controller
                        name="whoIs_client_1"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Кем приходится Клиенту Поручитель 1?'}
                                error={!!errors?.whoIs_client_1}
                                helperText={errors?.whoIs_client_1?.message}
                            />}
                    />

                    <Controller
                        name="lastName_client_1"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Фамилия'}
                                error={!!errors?.lastName_client_1}
                                helperText={errors?.lastName_client_1?.message}
                            />}
                    />

                    <Controller
                        name="firstName_client_1"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Имя'}
                                error={!!errors?.firstName_client_1}
                                helperText={errors?.firstName_client_1?.message}
                            />}
                    />

                    <Controller
                        name="secondName_client_1"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Отчество'}
                                error={!!errors?.secondName_client_1}
                                helperText={errors?.secondName_client_1?.message}
                            />}
                    />

                    <Controller
                        name="phone_client_1"
                        control={control}
                        rules={{
                            required: 'Заполните поле',
                            validate: (value) => {

                                const phone = value.replaceAll(' ', '')

                                

                                if (!validator.isMobilePhone(phone))
                                    return 'Введите коректный номер телфона'

                                return undefined
                            }
                        }}
                        render={({field}) =>
                            <MuiTelInput label={'Телефон'} langOfCountryName={'RU'} error={!!errors?.phone_client_1}
                                         helperText={errors?.phone_client_1?.message} {...field} fullWidth
                                          margin={'normal'}/>}
                    />

                    <Controller
                        name="address_client_1"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Адрес'}
                                error={!!errors?.address_client_1}
                                helperText={errors?.address_client_1?.message}
                            />}
                    />
                </div>

                <div className={`lg:w-[47%] w-full mt-4`}>
                    <Controller
                        name="whoIs_client_2"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Кем приходится Клиенту Поручитель 2?'}
                                error={!!errors?.whoIs_client_2}
                                helperText={errors?.whoIs_client_2?.message}
                            />}
                    />

                    <Controller
                        name="lastName_client_2"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Фамилия'}
                                error={!!errors?.lastName_client_2}
                                helperText={errors?.lastName_client_2?.message}
                            />}
                    />

                    <Controller
                        name="firstName_client_2"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Имя'}
                                error={!!errors?.firstName_client_2}
                                helperText={errors?.firstName_client_2?.message}
                            />}
                    />

                    <Controller
                        name="secondName_client_2"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Отчество'}
                                error={!!errors?.secondName_client_2}
                                helperText={errors?.secondName_client_2?.message}
                            />}
                    />

                    <Controller
                        name="phone_client_2"
                        control={control}
                        rules={{
                            required: 'Заполните поле',
                            validate: (value) => {

                                const phone = value.replaceAll(' ', '')

                                

                                if (!validator.isMobilePhone(phone))
                                    return 'Введите коректный номер телфона'

                                return undefined
                            }
                        }}
                        render={({field}) =>
                            <MuiTelInput label={'Телефон'} langOfCountryName={'RU'} error={!!errors?.phone_client_2}
                                         helperText={errors?.phone_client_2?.message} {...field} fullWidth
                                          margin={'normal'}/>}
                    />

                    <Controller
                        name="address_client_2"
                        control={control}
                        rules={{
                            required: 'Заполните поле'
                        }}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Адрес'}
                                error={!!errors?.address_client_2}
                                helperText={errors?.address_client_2?.message}
                            />}
                    />
                </div>

                <div className={`lg:w-[47%] w-full mt-4`}>
                    <Controller
                        name="whoIs_client_3"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Кем приходится Клиенту Поручитель 3?'}
                                error={!!errors?.whoIs_client_3}
                                helperText={errors?.whoIs_client_3?.message}
                            />}
                    />

                    <Controller
                        name="lastName_client_3"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Фамилия'}
                                error={!!errors?.lastName_client_3}
                                helperText={errors?.lastName_client_3?.message}
                            />}
                    />

                    <Controller
                        name="firstName_client_3"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Имя'}
                                error={!!errors?.firstName_client_3}
                                helperText={errors?.firstName_client_3?.message}
                            />}
                    />

                    <Controller
                        name="secondName_client_3"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Отчество'}
                                error={!!errors?.secondName_client_3}
                                helperText={errors?.secondName_client_3?.message}
                            />}
                    />

                    <Controller
                        name="phone_client_3"
                        control={control}
                        rules={{
                            validate: (value) => {

                                if(value.length === 0)
                                    return undefined

                                const phone = value.replaceAll(' ', '')

                                

                                if (!validator.isMobilePhone(phone))
                                    return 'Введите коректный номер телфона'

                                return undefined
                            }
                        }}
                        render={({field}) =>
                            <MuiTelInput label={'Телефон'} langOfCountryName={'RU'} error={!!errors?.phone_client_3}
                                         helperText={errors?.phone_client_3?.message} {...field} fullWidth
                                          margin={'normal'}/>}
                    />

                    <Controller
                        name="address_client_3"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Адрес'}
                                error={!!errors?.address_client_3}
                                helperText={errors?.address_client_3?.message}
                            />}
                    />
                </div>

                <div className={`lg:w-[47%] w-full mt-4`}>
                    <Controller
                        name="whoIs_client_4"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Кем приходится Клиенту Поручитель 4?'}
                                error={!!errors?.whoIs_client_4}
                                helperText={errors?.whoIs_client_4?.message}
                            />}
                    />

                    <Controller
                        name="lastName_client_4"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Фамилия'}
                                error={!!errors?.lastName_client_4}
                                helperText={errors?.lastName_client_4?.message}
                            />}
                    />

                    <Controller
                        name="firstName_client_4"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Имя'}
                                error={!!errors?.firstName_client_4}
                                helperText={errors?.firstName_client_4?.message}
                            />}
                    />

                    <Controller
                        name="secondName_client_4"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Отчество'}
                                error={!!errors?.secondName_client_4}
                                helperText={errors?.secondName_client_4?.message}
                            />}
                    />

                    <Controller
                        name="phone_client_4"
                        control={control}
                        rules={{
                            validate: (value) => {

                                if(value.length === 0)
                                    return undefined

                                const phone = value.replaceAll(' ', '')

                                if (!validator.isMobilePhone(phone))
                                    return 'Введите коректный номер телфона'

                                return undefined
                            }
                        }}
                        render={({field}) =>
                            <MuiTelInput label={'Телефон'} langOfCountryName={'RU'} error={!!errors?.phone_client_4}
                                         helperText={errors?.phone_client_4?.message} {...field} fullWidth
                                          margin={'normal'}/>}
                    />

                    <Controller
                        name="address_client_4"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                margin={'normal'}
                                fullWidth label={'Адрес'}
                                error={!!errors?.address_client_4}
                                helperText={errors?.address_client_4?.message}
                            />}
                    />
                </div>

            </div>

            <button
                disabled={isSubmitting}
                className={`block mx-auto my-8 px-4 py-2 text-xl duration-300 hover:scale-105 ${isSubmitting ? 'bg-gray-300' : 'bg-green-300'}`}>Отправить
            </button>
        </form>
    );
};

export default Form;